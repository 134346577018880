<template>
    <v-app id="marketing-plattform">
        <v-content>
            <v-container fluid fill-height>
                <v-layout align-center justify-center>
                    <v-flex xs12 sm8 md4>
                        <v-img class="logo-image" src="images/logo/logoMarketingportal.png"></v-img>
                        <v-card class="elevation-12">
                            <v-form v-model="formIsValid" v-on:submit="submit" @keydown.enter.prevent lazy-validation action="/login" method="post" ref="loginForm" id="loginForm">
                                <v-toolbar dark color="primary">
                                    <v-toolbar-title>Anmeldung</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                    <v-alert
                                            :value="true"
                                            type="info"
                                            outline
                                            icon="info"
                                            dismissible
                                    >
                                        Anmeldung ohne Passwort!<br>Der Zugangslink wird an Ihre Mailadresse gesendet.<br>
                                        <strong>Bitte verwenden Sie die zentrale Mailadresse der Geschäftsstelle</strong> - nicht Ihre eigene!
                                    </v-alert>
                                </v-card-text>
                                <v-card-text>
                                        <v-text-field
                                            value=""
                                            id="email"
                                            ref="email"
                                            prepend-icon="person"
                                            name="email"
                                            label="Email*"
                                            type="email"
                                            placeholder="...@oevo.de"
                                            :rules="emailRules"
                                            required
                                        ></v-text-field>
                                        <input type="hidden" name="_token" id="_token" value="">
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="!formIsValid" @click.stop="submit" color="primary">Absenden</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
        <v-footer class="px-4">
            <span>Marketingportal | Öffentliche Oldenburg</span>
            <v-spacer></v-spacer>
            <span>&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
    </v-app>
</template>

<script>
    export default {
        name: 'Login',
        props: {
            source: String
        },
        data() {
            return {
                submitBtnDisabled: false,
                formIsValid: false,
                showInfo: false,
                emailRules: [
                    v => !!v || "Die Mailadresse wird benötigt.",
                    v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "Ungültige Mailadresse!",
                ]
            }

        },
        methods: {
            submit: function(event) {
                if(this.$refs.loginForm.validate()){
                    this.submitBtnDisabled = true;
                    loginForm.submit();
                    return true;
                }
            }
        },
        mounted: function(){
            document.getElementById("app").click();
            document.getElementById('_token').value = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            this.$refs.email.$el.querySelector('input').focus();
        }
    }
</script>

<style>
    .v-btn, .v-tabs__div{
        text-transform: none !important;
    }
    .logo-image {
        margin-bottom: 2em;
        width: 100%;
    }
    .v-image__image.v-image__image--cover {
        background-size: contain;
    }
    .v-toolbar__content {
        font-size: 18px !important;
    }
</style>
