import './bootstrap';
import Vue from 'vue';
// import Vuetify from 'vuetify';
import Vuetify, {
    VApp,
    VContent,
    VContainer,
    VLayout,
    VFlex,
    VImg,
    VIcon,
    VForm,
    VTextField,
    VBtn,
    VToolbar,
    VToolbarTitle,
    VAlert,
    VCard,
    VCardText,
    VCardActions,
    VSpacer,
    VFooter
} from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';
// import store from './stores';
// import Routes from '@/js/routes.js';
import Login from '@/js/pages/Login';


Vue.use(Vuetify, {
    theme: {
        primary: '#004494',
        secondary: '#B2C9E2',
        accent: '#E20020',
        error: '#da291c',
        warning: '#ff8200',
        info: '#00a3ad',
        success: '#97d700'
    },
    iconfont: 'md',
    components: {
        VApp,
        VContent,
        VContainer,
        VLayout,
        VFlex,
        VImg,
        VIcon,
        VForm,
        VTextField,
        VBtn,
        VToolbar,
        VToolbarTitle,
        VAlert,

        VCard,
        VCardText,
        VCardActions,
        VSpacer,
        VFooter
    },
    directives: {
        Ripple
    }
});

const app = new Vue({
    el: '#app',
    components: { Login }
});






